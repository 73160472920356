<template >
  <!-- -->
  <div >
    <v-app-bar   class="globalRadiusAppBar"  app :dark="$store.state.isDarkMode" height="65" :class="{ appBarRtl: $i18n.locale == 'en' } ">
      <!-- <v-btn  class="mr-3" elevation="1" fab small text  @click="setDrawer(!drawer)">
        <v-icon  v-if="value"> mdi-view-quilt </v-icon>
        <v-icon  v-else> mdi-dots-vertical </v-icon>
      </v-btn> -->
      <v-col v-if="!authLog" md="11">
        <v-row >
          <v-avatar size="55" class="ml-5">
            <v-img src="@/assets/robogex.png" ></v-img>
          </v-avatar>
          <h4 style="margin-top: 20px; margin-left: 15px;">{{ $t('Robogeex') }}</h4>
        </v-row>
      </v-col>
      <v-btn class="mr-3" elevation="1" fab small text  @click="changeMode">
        <v-icon  v-if="isDarkMode"> mdi-white-balance-sunny </v-icon>
        <v-icon  v-else> mdi-moon-waxing-crescent </v-icon>
      </v-btn>
      <v-btn class="mr-3" elevation="1" fab small text @click="changeLang">
        <v-icon> mdi-earth </v-icon>
      </v-btn>
      <v-menu v-if="authLog" bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="mr-3" elevation="1" fab small text v-bind="attrs" v-on="on" @click="getNot">
            <v-badge v-if="count != 0" color="red" overlap bordered>
              <template v-slot:badge>
                <span>{{ count }}</span>
              </template>
              <v-icon color="white">mdi-bell</v-icon>
            </v-badge>
            <v-icon v-else color="white">mdi-bell</v-icon>
          </v-btn>
        </template>
        <v-list :tile="false" nav>
          <div>
            <app-bar-item :loading="loading" v-for="(n, i) in notifications" :key="`item-${i}`">
              <v-list-item-title v-text="n.title" @click="showDist(n)" />
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>
      <v-col md="6" v-if="authLog" style="margin-top: 30px;">
        <v-autocomplete :key="autocomKey" :items="childrens" dense flat clearable v-model="itemSearch" outlined rounded item-text="name"
          item-value="value" class="flex-full-width" density="comfortable" item-props menu-icon=""
          prepend-inner-icon="mdi-magnify" theme="light" variant="solo" @keypress="fetchStudentsAutoComplete($event)"
          @input="inputSearch()">
        </v-autocomplete>
      </v-col>
      <v-col md="1" class="justify-end">
      </v-col>
      <v-col class="justify-end" style="width: 50px;">
        <v-row v-if="my_info">
          <v-avatar size="55" class="ml-5">
            <v-img :src="my_info.image" v-if="my_info.image"></v-img>
          </v-avatar>
          <h4 style="margin-top: 20px; margin-left: 15px;">{{ my_info.name }}</h4>
        </v-row>

        

      </v-col>

           <!-- <v-icon v-else size="50"> mdi-alert-octagram </v-icon>  -->

      <!-- <p style=" font-size: 17.5px; margin: 0 3px">{{ getRouteNameAddition }}</p> -->

      <!-- <v-icon class="appBar__newRequest-icon">mdi-plus-thick</v-icon> -->
      <!-- <v-text-field
      :label="$t('search')"
      :dark="true"
      hide-details
      style="max-width: 200px"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small color="white">
          <v-icon color="#353149">mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->



      <!-- <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon color="white">mdi-view-dashboard</v-icon>
    </v-btn> -->



      <!-- <v-btn class="ml-2" min-width="0" text to="/pages/user">
      <v-icon color="white">mdi-account</v-icon>
    </v-btn> -->
    </v-app-bar>
  </div>
</template>

<script>
// Components
import axios from "axios";
import { VHover, VListItem } from "vuetify/lib";
import i18n from '@/i18n';
import { mapState, mapMutations, mapGetters } from "vuex";
import debounce from "../../../../helpers/debounce";
// import enTranslations from '@/locales/en.json';
// import arTranslations from '@/locales/ar.json';

export default {
  name: "DashboardCoreAppBar",

  components: { 
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  // watch: {
  //   "$i18n.locale": function (){
  //     this.autocomKey += 1;
    
  //   },
  // },
  data: () => ({
    authLog: false,
    autocomKey: 0,
    notifications: [],
    loading: false,
    count: null,
    isDarkMode: false,
    itemSearch: null,
    my_info: null,
    childrensLoading: false,
    childrens: [],
    currentTheme: "dark",
    // listSearch:[{name: "inventory",value: "/inventories"}],
    listSearch: [
      {
        name: i18n.t("inventory"),
        value: "/inventories",
      },
      {
        name: i18n.t("material-tree"),
        value: "/material-tree",
      },
      {
        name: i18n.t("bill-management"),
        value: "/bill-management",
      },
      {
        name: i18n.t("Bills"),
        value: "/invoice-list",
      },
      {
        name: i18n.t("Material Types"),
        value: "/material-types",
      },
      {
        name: i18n.t("invoiceTemplate"),
        value: "/invoice-Template",
      },
      {
        name: i18n.t("Inventory Reports"),
        value: "/inventoryReports",
      },
      {
        name: i18n.t("accounting tree"),
        value: "/accounting",
      },
      {
        name: i18n.t("cost center"),
        value: "/costCenters",
      },
      {
        name: i18n.t("Accounting entry"),
        value: "/accountingEntry",
      },
      {
        name: i18n.t("accounting templates"),
        value: "/accountingTemplates",
      },
      {
        name: i18n.t("financial orders"),
        value: "/financialOrder",
      },
      {
        name: i18n.t("Document Management"),
        value: "/document-management",
      },
      {
        name: i18n.t("Documents"),
        value: "/documents-list",
      },
      {
        name: i18n.t("pay installments"),
        value: "/payInstallments",
      },
      {
        name: i18n.t("Financial Reports"),
        value: "/reports",
      },
      {
        name: i18n.t("twirling"),
        value: "/accountingRotation",
      },
      {
        name: i18n.t("cafeteria sale"),
        value: "/buffet",
      },
      {
        name: i18n.t("cafeteria products"),
        value: "/buffetProducts",
      },
      {
        name: i18n.t("Materials Movement"),
        value: "/materialMovement",
      },
      {
        name: i18n.t("Materials Inventory"),
        value: "/materialInventory",
      },
      {
        name: i18n.t("add a restriction"),
        value: "/createAccountingEntry",
      },
      {
        name: i18n.t("Students Reports"),
        value: "/students/reports",
      },
      {
        name: i18n.t("temporary zone"),
        value: "/temporary-area",
      },
      {
        name: i18n.t("Results Document"),
        value: "/graduation",
      },
      {
        name: i18n.t("students"),
        value: "/students",
      },
      {
        name: i18n.t("roles"),
        value: "/roles",
      },
      {
        name: i18n.t("boxes"),
        value: "/myBoxes",
      },
      {
        name: i18n.t("treasurers"),
        value: "/cashierBox",
      },
      {
        name: i18n.t("Cashier for financial orders"),
        value: "/cashierFinancialOrder",
      },
      {
        name: i18n.t("Warehouse"),
        value: "/warehouse",
      },
      {
        name: i18n.t("Sales Management"),
        value: "/salesManagement",
      },
      {
        name: i18n.t("Purchase Management"),
        value: "/purchaseManagement",
      },
      {
        name: i18n.t("lessons"),
        value: "/teacher-lessons",
      },
      {
        name: i18n.t("Weekly classes"),
        value: "/weekly-classes",
      },
      {
        name: i18n.t("teachers"),
        value: "/teachers",
      },
      {
        name: i18n.t("parents"),
        value: "/parents",
      },
      {
        name: i18n.t("Drivers Reports"),
        value: "/drivers/reports",
      },
      {
        name: i18n.t("drivers"),
        value: "/drivers",
      },
      {
        name: i18n.t("Trips"),
        value: "/driversRoutes",
      },
      {
        name: i18n.t("Meals Reports"),
        value: "/meal/reports",
      },
      {
        name: i18n.t("ordered meals"),
        value: "/orderedMeals",
      },
      {
        name: i18n.t("all meals"),
        value: "/meal",
      },
      {
        name: i18n.t("Employees"),
        value: "/HR/PublicRecord",
      }, {
        name: i18n.t("iniate"),
        value: "/HR/initiate",
      }, {
        name: i18n.t("main views"),
        value: "/mainViews",
      }, {
        name: i18n.t("admins"),
        value: "/admins",
      }, {
        name: i18n.t("Education section reports"),
        value: "/reportTechers",
      }, {
        name: i18n.t("attachments"),
        value: "/attachments",
      }, {
        name: i18n.t("Homeworks"),
        value: "/homeworks",
      }, {
        name: i18n.t("All Messages"),
        value: "/allMessages",
      }, {
        name: i18n.t("Messages Sent"),
        value: "/messagesSent",
      }, {
        name: i18n.t("Incoming Mail"),
        value: "/incomingMail",
      }, {
        name: i18n.t("HR Reports"),
        value: "/HR/reports",
      }, {
        name: i18n.t("hr.payments"),
        value: "/HR/payments",
      }, {
        name: i18n.t("hr.calculations"),
        value: "/HR/calculations",
      }, {
        name: i18n.t("hr.loans"),
        value: "/HR/loans",
      }, {
        name: i18n.t("hr.variations"),
        value: "/HR/variations",
      }, {
        name: i18n.t("hr.vacations"),
        value: "hr.vacations",
      }, {
        name: i18n.t("hr.time-attendance"),
        value: "hr.time-attendance",
      }, {
        name: i18n.t("All Tasks"),
        value: "/allTasks",
      }, {
        name: i18n.t("Tasks Sent"),
        value: "/tasksSent",
      }, {
        name: i18n.t("My Tasks"),
        value: "/myTasks",
      }, {
        name: i18n.t("add role"),
        value: "/createRole",
      }, {
        name: i18n.t("add student"),
        value: "/createStudent",
      }, , {
        name: i18n.t("Add a student to the temporary area"),
        value: "/create-temp-student",
      }, {
        name: i18n.t("Absent Report Students"),
        value: "/students/absentReport",
      }, {
        name: i18n.t("Results Report"),
        value: "/students/resultsReport",
      }, {
        name: i18n.t("Trial Balance"),
        value: "/trialBalance",
      }, {
        name: i18n.t("Ledger"),
        value: "/ledger",
      }, , {
        name: i18n.t("Resturant Report"),
        value: "/meal/resturantReport",
      }, {
        name: i18n.t("Buffet Card Report"),
        value: "/meal/buffetCardReport",
      }, {
        name: i18n.t("Trips Report"),
        value: "/drivers/tripsReport",
      }, {
        name: i18n.t("Details Trips Report"),
        value: "/drivers/detailsTripsReport",
      }, {
        name: i18n.t("add parent"),
        value: "/addUser/parent",
      }, , {
        name: i18n.t("Lessons Report"),
        value: "/lessonReport",
      }, {
        name: i18n.t("add administrator"),
        value: "/addUser/admin",
      }, {
        name: i18n.t("educational year"),
        value: "/educationalYear",
      }, {
        name: i18n.t("Log"),
        value: "/log",
      }, {
        name: i18n.t("Backup"),
        value: "/backup",
      }, , {
        name: i18n.t("Grade Structure"),
        value: "/gradeStructure",
      }, {
        name: i18n.t("subjects"),
        value: "/subjects",
      }, {
        name: i18n.t("education class"),
        value: "/educationClass",
      }, , {
        name: i18n.t("rooms"),
        value: "/rooms",
      }, {
        name: i18n.t("floors"),
        value: "/floors",
      }, {
        name: i18n.t("classes type"),
        value: "/gradeTypes",
      }, , {
        name: i18n.t("calsses"),
        value: "/grades",
      }, {
        name: i18n.t("terms type"),
        value: "/termTypes",
      }, {
        name: i18n.t("terms"),
        value: "/terms",
      },
      //,{
      //   name: i18n.t(""),
      //   value: "",
      // },{
      //   name: i18n.t(""),
      //   value: "",
      // },{
      //   name: i18n.t(""),
      //   value: "",
      // },
    ]
  }),

  computed: {
    ...mapGetters(["getRouteNameAddition"]),
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",

    }),
    async fetchStudentsAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.childrensLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/student/auto-complete?type=app_bar", {
            params: {
              name: searchValue,
            },
          });
          this.childrens = this.listSearch.concat(searchResult.data.data);

        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      }, 1000)();
    },
    inputSearch() {
      let url = this.itemSearch;
      this.itemSearch = null;

      if (url != null) {

        this.$router.push(
          `${url}`
        );
      }
    },
    async showDist(item) {
      const res = await axios.post(`/notification/${item.id}`);
      this.$router.push(`/${item.data.path}`);
    },
    async getNot() {
      this.loading = true;
      const res = await axios.get("/notification");
      this.notifications = res.data.data;
      this.loading = false;
    },
    changeLang() {
      if (this.$i18n.locale == 'en') {
        // this.$i18n.setLocaleMessage('en', enTranslations);
        this.$i18n.locale = 'ar';
        localStorage.setItem('lang', 'ar');
        this.$vuetify.rtl = true;
      } else if (this.$i18n.locale == 'ar') {
        // this.$i18n.setLocaleMessage('ar', arTranslations);
        this.$i18n.locale = 'en';
        localStorage.setItem('lang', 'en');
        this.$vuetify.rtl = false;
      }
      this.childrens = this.childrens;
    },
    changeMode() {
      console.log(this.isDarkMode);
      this.isDarkMode = !this.isDarkMode;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode);
      if(this.isDarkMode){
        localStorage.setItem('theme', 'dark');
        document.documentElement.setAttribute('data-theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light');
        document.documentElement.setAttribute('data-theme', 'light');
      }
    },
  },
  async created() {
    const token = localStorage.getItem('access_token');
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  axios.defaults.headers.common["term"] =
        localStorage.getItem("term");
}
    this.$store.state.drawerList = JSON.parse(localStorage.getItem('drawerList')) ?? null; 
    // JSON.parse(localStorage.getItem('DrawerList')) : this.$store.state.drawerList;
    // this.$store.state.drawer = localStorage.getItem('Drawer') ? localStorage.getItem('Drawer') : this.$store.state.drawer;
    this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    if (this.$i18n.locale == 'en') {
       
        this.$vuetify.rtl = false;
      } else if (this.$i18n.locale == 'ar') {
       
        this.$vuetify.rtl = true;
      }
    this.currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : this.currentTheme;
    if (this.currentTheme == "dark"){
      this.isDarkMode = true;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode); 
      document.documentElement.setAttribute('data-theme', 'dark');
      this.changeMode();
      this.changeMode();
    } else { 
      this.isDarkMode = false;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode); 
      document.documentElement.setAttribute('data-theme', 'light');
      this.changeMode();
      this.changeMode();
    }

    if(localStorage.getItem('access_token')) {
      this.authLog = true;
      const res = await axios.get(`/notification/count`);
      const resUser = await axios.get(`/user/my-info`);
      this.count = res.data.data.notification_count;
      this.my_info = resUser.data.data;
    }
    this.childrens = this.listSearch;
    this.itemSearch = null;
  },
};
</script>

<style>
.appBarRtl {
  direction: ltr;
}

.appBar__newRequest-icon {
  border: 1px solid #66bb6a;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: #66bb6a !important;
  transition: 0.5s;
}

.appBar__newRequest-icon:hover {
  animation: scaleItem 0.5s linear infinite alternate;
  color: white !important;
  background: #66bb6a;
}

@keyframes scaleItem {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
</style>
